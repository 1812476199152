<template>
  <div class="page-container bg-fd9c09">
    <div class="relative">
      <img src="../../assets/img/dividend/bg_sh.png" class="w-full h-auto" alt="Top Background" />
    </div>
    <div class="px-4 -mt-24 pb-2">
      <div v-for="(item, index) in idoList" :key="item.id" class="mb-4">
        <div class="relative">
          <img src="../../assets/img/dividend/bg_sh_item.png" class="w-full h-auto" alt="Background" />
          <div class="absolute inset-0 flex flex-col justify-between p-4">
            <div class="flex flex-col">
              <span class="text-white text-lg font-bold">{{ item.name }}</span>
              <span class="text-yellow-400 text-sm font-bold mt-1" v-if="item.name == '商会总理事长'">(办理后有银行行长联系您资金分配)</span>
              <span class="text-white text-sm mt-1" v-else>{{index==0?'还有少量名额':'申请职位'}}</span>
            </div>
            <div class="flex flex-col mb-4">
              <span class="text-yellow-400 text-lg font-bold">{{ item.price }}元</span>
            </div>
            <button
              :class="[
                'px-6 py-2 rounded-full font-bold',
                isPositionFull(index) ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-white text-FF7B06'
              ]"
              @click="onClickItem(item, index)"
              :disabled="isPositionFull(index)"
            >
              {{ isPositionFull(index) ? '此职位已满员' : '申请职位' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  data() {
    return {
      idoList: [],
    };
  },
  computed: {
    isPositionFull() {
      return (index) =>  index === 2;
    },
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickItem(item, index) {
      if (this.isPositionFull(index)) {
        return; // Do nothing if the position is full
      }
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source: 2,
          price: item.price,
          name: item.name,
          id: item.id,
        },
      });
    },
    async getIdoList() {
      try {
        const response = await axios.get(api.welfareList, { params: { type: 5 } });
        if (response) {
          this.idoList = response.data.idoList;
        }
      } catch (error) {
        console.error("Failed to fetch IDO list:", error);
      }
    },
  },
  mounted() {
    this.getIdoList();
  },
};
</script>

<style scoped>
.page-container {
  min-height: 100vh;
  padding-bottom: 50px; /* Adjust this value based on your navigation bar height */
  box-sizing: border-box;
}

.text-FF7B06 {
  color: #FF7B06;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
