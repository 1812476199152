<template>
  <div class="apply-part">
    <van-nav-bar
      title="入党申请"
      left-text=""
      left-arrow
      @click-left="goBack"
    />

    <div class="content">
      <div class="banner">
        <img src="@/assets/img/profile/top_rdsq.png" alt="党旗" class="w-full">
      </div>

      <div class="form-section">
        <h2 class="form-title">填写入党信息</h2>
        <van-form @submit="submitForm">
          <van-field
            v-model="form.name"
            name="name"
            label="真实姓名"
            placeholder="请输入姓名"
          />
          <van-field
            v-model="form.idCard"
            name="idCard"
            label="身份证号码"
            placeholder="请输入身份证号码"
          />
          <div class="hint">
            <van-icon name="info-o" color="#999" size="14" />
            <span class="hint-text">实名认证于保证信息真实性用，不会对信息进行任何泄露</span>
          </div>
          <div style="margin: 16px;">
            <van-button round block type="danger" native-type="submit">
              提交入党信息
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApplyPart',
  data() {
    return {
      form: {
        name: '',
        idCard: ''
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    submitForm() {
      if (!this.form.name) {
        this.$toast('请输入真实姓名');
        return;
      }
      if (!this.form.idCard) {
        this.$toast('请输入身份证号码');
        return;
      }
      // const idCardPattern = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
      // if (!idCardPattern.test(this.form.idCard)) {
      //   this.$toast('请输入正确的身份证号码');
      //   return;
      // }


      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source: 9,
          price: 300,
          name: '入党费',
          id: '',
        },
      });
    }
  }
}
</script>

<style lang="less" scoped>
.apply-part {
  min-height: 100vh;
  background-color: #f5f5f5;

  .content {
    .banner {
      margin-bottom: 20px;
    }

    .form-section {
      background-color: #fff;
      padding: 20px;

      .form-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .hint {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;

        .hint-text {
          font-size: 12px;
          color: #999;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
