<template>
  <div class="h-full w-full bg-page">
    <div class="overflow-hidden w-full">
      <div class="page-content-2 pb-safe">
        <div class="flex flex-col m-4">
          <div class="flex flex-row items-center bg-info">
            <div class="bg-white rounded-full p-1 mr-2" @click="jumpToPage('userInfo')">
              <img class="rounded-full w-12 h-12 "
                   :src="userInfo.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
                   alt="">
            </div>
            <div class=" flex-1 flex text-white flex-col">
              <div class="flex flex-row items-center justify-between">
                <div class="flex items-center">
                  <p class="font-bold text-base mr-2">{{ displayName }}</p>
                  <span v-if="userInfo.mcdName" class="bg-yellow text-black text-xs px-2 py-1 rounded-full">
                    {{ userInfo.mcdName }}
                  </span>
                  <span v-if="userInfo.is_identity_term==1&&userInfo.is_party==0" class="bg-cblue text-white text-xs px-2 py-1 ml-1 rounded-full">
                   个人终审办理缴费成功
                  </span>
                  <span v-if="userInfo.is_party==1" class="bg-cblue text-white text-xs px-2 py-1 ml-1 rounded-full">
                   党员
                  </span>
                  <span v-if="userInfo.is_vip==1" class="bg-gold text-white text-xs px-2 py-1 ml-1 rounded-full">
                   VIP
                  </span>
                </div>
              </div>
              <div class="flex flex-row items-center justify-between">
                <div>
                  <p class="text-sm mt-1 text-E1E1E1">手机号:{{ userInfo.name|maskPhoneNumber }}</p>
                </div>
<!--                <img src="@/assets/img/profile/btn_sign.png" style="height: 26px;" @click="onSignIn"-->
<!--                     alt=""/>-->
              </div>
            </div>
          </div>
          <div class="my-2">
            <MyAssets :identifyInfo="identifyInfo" />
            <img src="@/assets/img/profile/rdsq.png" class="w-full mt-2" @click="jumpToPage('applyPart')" alt="" />
            <img src="@/assets/img/profile/grsf.jpg" class="w-full mt-2" @click="showGrsfDialog()" alt="" />
<!--            <img src="@/assets/img/profile/hysj.png" class="w-full mt-2" @click="jumpToPage('upgradeMember')" alt="" />-->

            <img src="@/assets/img/profile/qsbzj.png" class="w-full mt-2" @click="showDialog" alt="" />
            <img src="@/assets/img/profile/udun.png" class="w-full mt-2"  @click="jumpToPage('uDun')" alt="" />
            <img src="@/assets/img/profile/wxsfzbl.png" class="w-full mt-2" @click="jumpToPage('yjsfz')" alt="" />
            <img src="@/assets/img/profile/banner.png" class="w-full mt-2" @click="jumpToPage('digitalBank')" alt="" />
            <img src="@/assets/img/profile/bank.png" class="w-full mt-2" @click="jumpToPage('bankCard')" alt="" />
          </div>
          <UserTools/>
          <button class="bg-white text-primary font-bold text-lg rounded text-center py-2 my-4  "
                  @click="onLoginOut">退出登录
          </button>
        </div>
      </div>
    </div>
    <popup v-model="show"  class="w-11/12 max-w-md rounded-lg" position="center">
      <div class="bg-white p-4 rounded-lg">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xxl font-bold">签署保证金</h2>
          <Icon name="cross" @click="close" />
        </div>

        <div class="text-base">
          <p class="mb-2">国家安全保密中心即日起，正式介入五行天下，并号召所有会员用户共同对抗，虚假，不实，等问谍行为，作为我国云家人公民身份，既日起，所有五行天下的会员必须签署安全保密协议才会给予放款！</p>
          <p class="mb-2">推动五行天下所有用户彻底摆脱贫困，做到【合法，合规，合理】的去使用自己的资金，不得出租，出借，贩卖账户以及银行卡信息，不被非法平台利用诱惑，确保账户安全习惯，保障用户资金安全使用，安全提取消费，所有会员必须签署保密协议！</p>
        </div>

        <div class="mt-4" >
          <field v-model="signer" label="协议签署人:" label-width="80" />
          <field :value="currentDate" label="签署日期:" readonly  labelWidth="80"/>
        </div>

        <div class="mt-4 text-center">
          <p class="text-base mb-2">我同意并知晓签署内容，<span class="text-red-500">签署保证金 ¥ 500</span></p>
          <Button type="danger" block @click="agree">同意签署</Button>
        </div>
      </div>

<!--      <div class="absolute inset-x-0 bottom-0 h-16 bg-contain bg-no-repeat bg-bottom" style="background-image: url('/path/to/your/background-image.png');"></div>-->
    </popup>
    <popup v-model="showGrsf"  class="w-11/12 max-w-md rounded-lg" position="center">
      <div class="bg-white p-4 rounded-lg">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xxl font-bold">个人身份终审办理</h2>
          <Icon name="cross" @click="close" />
        </div>

        <div class="text-base">
          <p class="mb-2">终审成功将会为你本人所在地提供一对一专项服务，取款现金进行专属贵宾服务，全程由当地取现金银行安保人员接送特权，还可以获得100万元现金慰问金送往您的家中！</p>
          <p class="mb-2">如果您还没有申请五行云家商会职位，请先办理申请，有效防止不法分子！</p>
        </div>
        <div class="mt-4 text-center">
          <Button type="danger" block @click="toDo">立即办理</Button>
        </div>
      </div>

<!--      <div class="absolute inset-x-0 bottom-0 h-16 bg-contain bg-no-repeat bg-bottom" style="background-image: url('/path/to/your/background-image.png');"></div>-->
    </popup>
    <action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect"/>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import MyAssets from "@/components/MyAssets";
import UserTools from "@/components/UserTools";
import {Field, Icon, Popup, Button, ActionSheet} from "vant";

export default {
  name: "index",
  components: {UserTools, MyAssets,Popup,Icon,Field,Button,ActionSheet},
  computed: {
    ...mapState("user", ["userInfo","identifyInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      console.log(this.identifyInfo);
      if (this.identifyInfo&&this.identifyInfo.name) {
        return this.identifyInfo.name;
      }
       else {
        return "未设置";
      }
    },
    currentDate() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      walletInfo: {},
      e_cny:"0.00",
      income_wallet:"0.00",
      stock:0,
      recommendRecords: [],
      show: false,
      signer: '',
      actions: [],
      showActionSheet: false,
      showGrsf: false,
    };
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getIdentifyInfo();
    // this.loadData();
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },
  methods: {
    ...mapActions("user", ["getUserInfo","getIdentifyInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onSignIn() {
      if (isNullOrEmpty(this.identifyInfo)||this.identifyInfo.status!=2) {
        this.$dialog.confirm({
          title: '提示',
          message: '签到需要实名认证，是否前往实名认证？',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }

      axios
          .post(api.signIn)
          .then((res) => {
            this.$toast.success('签到成功!');
            this.getUserInfo();
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToWithdraw(type, title, amount) {
      if (type == 'annuity' && this.userInfo.activate == 0) {
        this.$dialog.alert({
          title: '提示',
          message: '购买养老基金即可提现',
          theme: 'round-button',
        }).then(() => {
          this.$router.push({
            name: "dividend"
          });
        });
        return

      }
      this.$router.push({
        name: "withdrawal",
        query: {type, title, amount},
      });
    },
    jumpToPage(name) {
      console.log('点击跳转',name);
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      if(this.userInfo.is_party == 1 && name == 'applyPart'){
        this.$dialog.alert({
          title: '提示',
          message: '你已入党',
          theme: 'round-button'
        })
        return;
      }

      this.$router.push({name});
    },
    onLoginOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出登录吗？',
      })
          .then(() => {
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch(() => {
          });
    },
    showDialog() {
      if(this.userInfo.is_margin == 0){
        this.show = true;
      }
      else{
        this.$dialog.alert({
          title: '提示',
          message: '您已完成签署',
          theme: 'round-button'
        }).then(() => {
        });
      }
    },
    showGrsfDialog() {
      if(this.userInfo.is_identity_term == 0){
        this.showGrsf = true;
      }
      else{
        this.$dialog.alert({
          title: '提示',
          message: '您已完成办理',
          theme: 'round-button'
        }).then(() => {
        });
      }
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.recommendRecords = res.data.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    loadData() {
      axios.get(api.welfareList,{params: {type: 4}})
          .then(res => {
            console.log('会员列表',res)
            this.actions = res.data.idoList
          })
          .catch(err => {
          });
    },
    close() {
      this.show = false;
    },
    agree() {
      // Handle agreement logic here
      console.log('Agreed', this.signer, this.currentDate);
      if(this.signer.trim() == ''){
        this.$toast('请填写签署人');
        return;
      }
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source:6,
          price:500,
          name:'保证金',
          id:'',
        },
      });
      this.close();
    },
    toDo() {
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source:8,
          price:500,
          name:'办理费',
          id:'',
        },
      });
      this.close();
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showActionSheet = false;
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source:2,
          price:item.price,
          name:'会员费',
          id:item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}
//
//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}
.bg-page {
  background-image: url('../../assets/img/profile/bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}


.header {
  background-image: url('../../assets/img/profile/bg-avatar.png');
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

.header a img {
  width: 48px;
  height: 48px;
}


.item-box {
  background: linear-gradient(0deg, #515253 0%, #333435 100%);
  box-shadow: 0px 5px 10px 3px rgba(20, 7, 0, 0.17);
  border-radius: 10px;
}



.item-btn {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}


.bg-primary {
  background-color: #B41212; // Adjust this color to match your primary color
}

.bg-yellow {
  background-color: #FFD700; // This is a golden yellow color, you can adjust it if needed
}

.bg-gold {
  background-color: #FFD700; // This is a golden color for the VIP badge
}

</style>
